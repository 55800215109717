<template>
  <TModal
    title="Select purchases"
    :show="show"
    @update:show="$emit('update:show', $event)"
    size="lg"
  >
    <template #actions>
      <div class="d-flex w-100">
        <TMessage content="Selected: ">
          <template #suffix>{{ ordersSelected.length }}</template>
        </TMessage>
        <TButton
          :options="{ disabled: lodash.isEmpty(ordersSelected) }"
          variant="outline"
          content="Select"
          icon="cil-check"
          class="ml-auto"
          @click="submitOrdersSelected"
        />
      </div>
    </template>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="order.purchase_payable"
      @click-clear-filter="clearFilter"
      resource=""
    >
      <template #append-actions="{ item }">
        <CCol class="px-1 py-1 ml-1 text-center my-auto" col="12">
          <TInputCheckbox
            :checked="lodash.findIndex(ordersSelected, ['id', item.id]) !== -1"
            @change="selectOrder(item)"
          />
        </CCol>
      </template>
      <template #supplier="{ item }">
        <td>
          <TMessage
            :content="item.supplier.name"
            noTranslate
            class="text-nowrap"
          />
        </td>
      </template>
      <template #id-filter>
        <TInputText :value.sync="filter.id" @update:value="filterChange" />
      </template>
      <template #outstanding="{ item }">
        <td>
          <TMessageMoney :amount="item.outstanding" :currency="item.currency_id" />
        </td>
      </template>
      <template #balance="{ item }">
        <td>
          <div class="d-flex">
            <TMessageMoney
              :amount="item.paid"
              :currency="item.currency_id"
              color="muted"
              noBold
            />
            <span class="mx-1">/</span>
            <TMessageMoney
              :amount="item.balance"
              :currency="item.currency_id"
              color="muted"
            />
          </div>
        </td>
      </template>
    </TTableAdvance>
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    supplier_id: {
      type: [String, Number],
      reuqired: false,
    },
  },
  data() {
    return {
      filter: {},
      ordersSelected: [],
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "supplier",
          label: "Supplier",
        },
        { key: "id", label: "Purchase Id", _style: "width: 200px" },
        { key: "balance", label: "Paid/Cost of goods" },
        { key: "outstanding", label: "Outstanding", sorter: true, },
      ],
    };
  },
  computed: {
    ...mapGetters({
      list: "order.purchase_payable.list",
    }),
  },
  watch: {
    show(show) {
      if (show) {
        this.filterChange();
      }
    },
  },
  methods: {
    submitOrdersSelected() {
      this.$emit("select", this.ordersSelected);
      this.$emit("update:show", false);
      this.ordersSelected = [];
    },
    selectOrder(order) {
      const index = this.ordersSelected.findIndex((x) => x.id === order.id);
      if (index < 0) {
        this.ordersSelected.push(order);
      } else this.ordersSelected.splice(index, 1);
    },
    filterChange() {
      let defaultFilter = {
        "filter[supplier_id]": this.supplier_id,
      };
      const filterList = this.lodash.mapKeys(
        this.filter,
        function (value, key) {
          return `filter[${key}]`;
        }
      );
      const query = this.lodash.pickBy({
        ...filterList,
        ...defaultFilter,
      });
      this.$store.dispatch("order.purchase_payable.apply-query", query);
    },
    clearFilter() {
      this.filter = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
  },
};
</script>
