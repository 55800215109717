<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Other payment" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{
            disabled:
              creating ||
              !input.debit_id ||
              !input.credit_id ||
              input.debit_id == input.credit_id,
          }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol>
              <SSelectReasonForArising
                label="Payment reason"
                class="mb-3"
                :value.sync="reasonIdSelected"
                :reasonList="reasonList"
                @change="reasonSelected = $event"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol>
              <SCardSelectObjectTransaction
                v-if="groupObjectByReason.length"
                :value.sync="object"
                :typesPick="groupObjectByReason"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="12">
              <CRow>
                <CCol md="3">
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol md="3">
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :selectOptions="{ disabled: true }"
                    :value.sync="input.currency_id"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="9">
              <TInputMoney
                label="Amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
            <CCol md="3">
              <TInputMoney
                label="Transfer fee"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input['transfer_fee[amount]']"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
          <SCardSelectReferenceReceipt
            v-if="groupInputByReason.length"
            :receipt.sync="temp"
            :typesPick="
              groupInputByReason[0] == 'all' ? [] : groupInputByReason
            "
          />
          <hr />
          <CRow>
            <CCol md="6">
              <SSelectAccountGroup
                label="Debit"
                :group="groupDebitByReason"
                class="mb-3"
                :value.sync="input.debit_id"
              />
            </CCol>
            <CCol md="6">
              <SSelectAccountGroup
                :group="['112']"
                label="Credit"
                class="mb-3"
                :value.sync="input.credit_id"
              />
            </CCol>
          </CRow>
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  data() {
    return {
      temp: {
        receiptable_id: "",
        receiptable_type: "",
      },
      object: {
        transactionable_type: "",
      },
      input: {
        credit_id: "112",
        debit_id: "",
      },
      reasonList: [
        {
          id: "payment-salary",
          name: "Payment/Advance payment salary",
          debit: ["334"],
          credit: ["112"],
          object: ["user"],
        },
        {
          id: "insurances",
          name: "Social, Health, Unemployment insurance",
          debit: ["338"],
          credit: ["112"],
          object: ["user"],
        },
        {
          id: "pay-taxes",
          name: "Pay taxes and fees to the state budget",
          debit: [
            "3331",
            "33311",
            "33312",
            "3332",
            "3333",
            "3334",
            "3335",
            "3336",
            "3337",
            "3338",
            "3339",
          ],
          credit: ["112"],
        },
        {
          id: "buy-supplies",
          name: "Buy supplies",
          debit: ["152"],
          credit: ["112"],
        },
        {
          id: "buy-tools",
          name: "Buy instrument and tools",
          debit: ["1531"],
          credit: ["112"],
        },
        {
          id: "office-and-warehouse-rental-costs",
          name: "Office and warehouse rental costs",
          debit: [
            "642",
            "6421",
            "6422",
            "6423",
            "6424",
            "6425",
            "6426",
            "6427",
            "6428",
          ],
          credit: ["112"],
        },
        {
          id: "other",
          name: "Other",
          debit: ["all"],
          credit: ["112"],
          reference: ["all"],
          object: ["all"],
        },
      ],
      receipts: [],
      reasonIdSelected: "other",
      reasonSelected: null,
    };
  },
  computed: {
    creating() {
      return this.$store.getters["accounting.transactions.creating"];
    },
    groupDebitByReason() {
      return this.reasonSelected?.debit || [];
    },
    groupInputByReason() {
      return this.reasonSelected?.reference || [];
    },
    groupObjectByReason() {
      return this.reasonSelected?.object || [];
    },
  },
  watch: {
    "temp.receiptable_type": function () {
      this.temp.receiptable_id = "";
    },
    "object.transactionable_type": function () {
      this.object.transactionable_id = "";
    },
    reasonSelected: {
      deep: true,
      handler(value) {
        if (value?.debit?.length == 1) {
          if (value.debit[0] == "all") {
            this.input.debit_id = "";
            return;
          }
          this.input.debit_id = value.debit[0];
        }
        if (value?.debit?.length > 1) {
          this.input.debit_id = "";
        }
      },
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch("accounting.transactions.create", this.getDataCreate())
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        type_id: "other-expenses",
        ...this.input,
        ...this.object,
      };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      if (this.temp.receiptable_type && this.temp.receiptable_id) {
        const nextIndexReceipt = this.receipts.length;
        params[`receipts[${nextIndexReceipt}][receiptable_type]`] =
          this.temp.receiptable_type;
        params[`receipts[${nextIndexReceipt}][receiptable_id]`] =
          this.temp.receiptable_id;
      }

      return this.lodash.pickBy(params, this.lodash.identity);
    },
    clearInput() {
      this.temp = {
        receiptable_id: "",
        receiptable_type: "",
      };
      this.object = {
        transactionable_type: "",
      };
      if (
        this.groupDebitByReason.length > 1 ||
        this.groupDebitByReason[0] == "all"
      ) {
        this.input = {
          credit_id: "112",
          debit_id: "",
        };
      }
      if (this.groupDebitByReason.length == 1) {
        this.input = {
          credit_id: "112",
          debit_id: this.groupDebitByReason[0],
        };
      }
      this.receipts = [];
    },
  },
};
</script>
