<template>
  <div class="w-100">
    <TTabs
      :tabs="tabs"
      :activeTab="$route.query.tab"
      @change-tab="changeTab"
      mountOnEnter
    >
      <template #pre-payment>
        <PrePayment />
      </template>
      <template #payment>
        <Payment />
      </template>
      <template #other-payment>
        <OtherPayment />
      </template>
    </TTabs>
  </div>
</template>

<script>
import PrePayment from "./PrePayment.vue";
import Payment from "./PaymentPurchases.vue";
import OtherPayment from "./OtherPayment.vue";

export default {
  components: {
    PrePayment,
    Payment,
    OtherPayment,
  },
  data() {
    return {
      tabs: [
        {
          key: "pre-payment",
          name: "Pre payment for supplier",
          icon: "cisLibraryBooks",
        },
        {
          key: "payment",
          name: "Payment purchases",
          icon: "cisLibraryBooks",
        },
        {
          key: "other-payment",
          name: "Other payment",
          icon: "cisLibraryBooks",
        },
      ],
    };
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
