<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap justify-content-start">
      <div class="d-flex align-items-center">
        <TMessage content="Payment purchases" bold />
        <TSpinner :loading="creating" />
      </div>
      <div class="ml-auto">
        <TButtonClear
          variant="outline"
          color="primary"
          class="mr-1"
          @click="clearInput"
        />
        <TButton
          content="Create"
          icon="cis-plus"
          @click="create"
          :options="{ disabled: creating }"
        />
      </div>
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol md="7">
          <CRow>
            <CCol md="6">
              <SSelectCard
                label="Card"
                class="mb-3"
                :value.sync="input.card_id"
                @change="input.currency_id = $event.currency_id"
              />
            </CCol>
            <CCol md="6">
              <TInputText
                label="Ref code"
                placeholder="Ref code"
                class="mb-3"
                :value.sync="input.code_ref"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <SSelectSupplier
                label="Supplier"
                :value.sync="input.transactionable_id"
                class="mb-3"
              />
            </CCol>
          </CRow>
          <CRow>
            <CCol md="6">
              <CRow>
                <CCol>
                  <TInputDateTime
                    label="Voucher date"
                    class="mb-3"
                    :value.sync="input.voucher_date"
                  />
                </CCol>
                <CCol>
                  <SSelectCurrency
                    label="Currency"
                    :prepend="[]"
                    :value.sync="input.currency_id"
                    :selectOptions="{ disabled: true }"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow>
            <CCol md="9">
              <TInputMoney
                label="Amount"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.amount"
              />
            </CCol>
            <CCol md="3">
              <TInputMoney
                label="Transfer fee"
                class="mb-3"
                :currency="input.currency_id"
                :value.sync="input.transfer_fee_amount"
              />
            </CCol>
          </CRow>
          <TInputTextarea
            label="Description"
            placeholder="Description"
            class="mb-3"
            :value.sync="input.description"
          />
        </CCol>
        <CCol md="5">
          <TInputFile label="Receipts" class="mb-3" :value.sync="receipts" />
        </CCol>
      </CRow>
      <CRow>
        <CCol>
          <TMessage content="Purchases" bold class="mb-2" />
          <TableInputPurchase
            ref="table_input_purchase"
            :total_amount="input.amount"
            @update:items="items = $event"
            :supplier_id="input.transactionable_id"
            :key="`table_input_purchase-${input.transactionable_id}`"
          />
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import TableInputPurchase from "../../../components/TableInputPurchase.vue";

export default {
  components: {
    TableInputPurchase,
  },
  data() {
    return {
      input: {},
      items: [],
      receipts: [],
    };
  },
  computed: {
    creating() {
      return this.$store.getters[
        "accounting.transactions_payment_supplier.creating"
      ];
    },
  },
  methods: {
    create() {
      this.$store
        .dispatch(
          "accounting.transactions_payment_supplier.create",
          this.getDataCreate()
        )
        .then(() => {
          this.clearInput();
        });
    },
    getDataCreate() {
      let params = {
        ...this.input,
        transactionable_type: "supplier",
      };
      this.receipts.forEach((receipt, index) => {
        params[`receipts[${index}][receiptable_type]`] = "file";
        params[`receipts[${index}][file]`] = receipt.file;
      });

      let payments = [];

      this.items.forEach((item) => {
        if (item.purchase.purchase_cost_to_pay > 0) {
          payments.push({
            amount: item.purchase.purchase_cost_to_pay,
            receiptable_type: "purchase",
            receiptable_id: item.purchase.id,
          });
        }
      });

      payments.forEach((payment, index) => {
        params[`payment_purchase[${index}][amount]`] = payment.amount;
        params[`payment_purchase[${index}][receipts][0][receiptable_type]`] =
          payment.receiptable_type;
        params[`payment_purchase[${index}][receipts][0][receiptable_id]`] =
          payment.receiptable_id;
      });

      params[`transfer_fee[amount]`] = this.input.transfer_fee_amount;

      return params;
    },
    clearInput() {
      this.input = {};
      this.receipts = [];
      this.$refs.table_input_purchase?.clear();
    },
  },
};
</script>
